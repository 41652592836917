<template>
  <v-container fluid>
    <v-row class="login-container text-center full-height">
      <v-col class="branding-container fill-height" cols="12" md="6">
        <div class="branding">
          <img class="login-logo" src="@/assets/images/ph-logo-horizontal-white.png" />
          <p class="login-system-title">Unified Service Portal</p>
        </div>
      </v-col>
      <v-col class="login-container-card" cols="12" md="6">
        <v-col class="login-card">
          <h1 class="login-title">Log In</h1>
          <br>
          <v-row>
            <v-btn x-large outlined color="#1A2791" elevation="0" class="ph-login-button" @click="googleLogin">
              <img class="google-logo" src="@/assets/images/google-logo.svg" />
              <p class="google-login-text">Log in with Google</p>
            </v-btn>
          </v-row>
          <v-row>
            <div class="ph-login-divider">
              <p class="ph-divider-text">or</p>
            </div>
          </v-row>
          <v-row>
            <p class="login-userpw-text"><b>Login with username and password</b></p>
            <v-col fluid cols="12" class="ph-textbox-container">
                <v-text-field
                placeholder="Username"
                hide-details
                outlined
                class="ph-textbox"
                :error="(!hasUsername && hasProcessLogin) ? true : false" v-model="userId" type="text"
                @keyup.enter="loginUser"
              ></v-text-field>
            </v-col>
            <v-col fluid cols="12" class="ph-textbox-container">
              <v-text-field
                placeholder="Password"
                hide-details
                :append-icon="(revealPassword === true) ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                class="ph-textbox"
                :error="(!hasPassword && hasProcessLogin) ? true : false" v-model="password"
                @click:append="revealPassword = !revealPassword"
                :type="revealPassword ? 'text' : 'password'"
                @keyup.enter="loginUser"
              ></v-text-field>
            </v-col>
            <router-link to="/forget-password" class="ph-forget-password">Forgot your password?</router-link>
            <br>
            <v-col fluid cols="12" class="ph-textbox-container">
              <v-btn x-large color="#1A2791" elevation="0" class="ph-login-button ph-login-userpw" @click="loginUser">
                Login
              </v-btn>
            </v-col>
          </v-row>
          <p class="footer">Copyright © {{getYear}} PERA HUB Unified Service Portal.  All rights reserved.</p>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { renderToast } from '@/utils'
// import VueCookies from 'vue-cookies'
// import CryptoJS from 'crypto-js'
import Bowser from 'bowser'

export default {
  name: 'LoginView',
  data () {
    return {
      userId: '',
      password: '',
      hasUsername: '',
      hasPassword: '',
      hasProcessLogin: false,
      revealPassword: false,
      getYear: format(new Date(), 'yyyy'),
      browserInfo: {}
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      isOTPpassed: 'isOTPpassed'
    })
  },
  created () {
    const userData = this.$cookies.get('USP_DATA')
    const userToken = this.$cookies.get('USP_TOKEN')

    if (this.isAuthenticated) {
      if (!this.isOTPpassed) {
        this.$router.push('/verify-otp-code')
      } else {
        if (userData && userToken) {
          location.href = this.$uspMainAppURL
        } else {
          this.$store.dispatch('resetAuthentication')
        }
      }
    } else {
      this.$store.dispatch('resetAuthentication')
    }
  },
  beforeMount () {
    const browser = Bowser.getParser(window.navigator.userAgent)
    this.browserInfo = browser
  },
  mounted () {
    if (localStorage.getItem('login_required_message')) {
      renderToast('error', 'Not logged-in', localStorage.getItem('login_required_message'))
    } else if (localStorage.getItem('logout_message')) {
      renderToast('info', 'Logged-out', localStorage.getItem('logout_message'))
    }

    window.addEventListener('message', this.onMessage, false)

    setTimeout(function () {
      localStorage.removeItem('login_required_message')
      localStorage.removeItem('logout_message')
    }, 3500)
  },
  beforeUnmount () {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    async loginUser () {
      // const browserInfo = this.browserInfo.parsedResult
      const { userId, password } = this
      this.hasProcessLogin = true
      this.hasUsername = !((userId === '' || userId === null || userId === undefined))
      this.hasPassword = !((password === '' || password === null || password === undefined))

      const payload = {
        user_name: userId,
        password: password
      }

      if (!this.hasUsername || !this.password) {
        renderToast('error', 'Invalid Input', 'The Username and Password is required.')
      } else {
        this.executeLogin(payload)
        // if (VueCookies.isKey('USP_MACH_KEY')) {
        //   let isValidated = false
        //   const susi = 'wlK7ZRNOBimj74oV9bqowfVPSqq+QD6t1YRwVWQMsHA='

        //   const payloadValidate = {
        //     browser: browserInfo.browser.name,
        //     key: CryptoJS.AES.decrypt(VueCookies.get('USP_MACH_KEY'), susi).toString(CryptoJS.enc.Utf8)
        //   }

        //   await this.$store.dispatch('validateMachine', payloadValidate).then(res => {
        //     if (res.code >= 200 && res.code <= 299) {
        //       isValidated = true
        //     } else {
        //       isValidated = false
        //       renderToast('warning', 'Machine Validation', res.error.message)
        //     }
        //   }).catch(err => {
        //     isValidated = false
        //     renderToast('warning', 'Machine Validation', err)
        //   })

        //   // Validations
        //   if (isValidated) {
        //     this.executeLogin(payload)
        //   }
        // } else {
        //   renderToast('warning', 'Machine Validation', 'This machine is not registered. Redirecting you to Machine Regitration Page...')

        //   setTimeout(function () {
        //     window.location.href = 'https://dev-ui-usp2-enrollment-spa-app-bpsk2lcqiq-as.a.run.app'
        //     // window.location.href = 'https://usp20-enrollment.perahub.com.ph'
        //   }, 2000)
        // }
      }
    },
    async executeLogin (payload) {
      this.$store.commit('SET_LOADING', true)
      await this.$store.dispatch('doLoginWithUserPW', payload).then(res => {
        this.$store.commit('SET_LOADING', false)
        if (res.result != null) {
          localStorage.removeItem('is_session_expired')
          this.$router.push('/verify-otp-code')
        } else {
          renderToast('error', 'Login Error', 'The credentials are invalid, please try again.')
        }
      }).catch(() => {
        renderToast('error', 'Login Error', 'The credentials are invalid, please try again.')
      })
    },
    googleLogin () {
      this.$store.dispatch('doLoginWithGoogle')
    },
    forgetPassword () {
      this.$router.push('/forget-password')
    },
    onMessage (event) {
      const parentURL = process.env.VUE_APP_USP_MAINTENANCE_URL || 'https://usp-maintenance-api-tenmgv6bka-as.a.run.app'

      if (event.origin === parentURL) {
        if (event.data.type === 'success') {
          localStorage.removeItem('is_session_expired')

          const that = this

          this.$store.commit('SET_AUTHENTICATED', true)
          this.$store.commit('SET_LOADING', true)

          const currentTime = new Date()
          const expireTime = new Date(currentTime.getTime() + (18 * 60 * 1000))

          this.$store.commit('SET_AUTH_TIMESTAMP', {
            timeLoggedIn: currentTime,
            timeToExpire: expireTime
          })

          if (Object.prototype.hasOwnProperty.call(event.data, 'user')) {
            this.$store.commit('SET_CURRENT_USER', event.data.user)

            console.log('Callback Data')
            console.log(event.data.user)

            setTimeout(function () {
              // this.$store.commit('SET_LOADING', false)
              that.$router.push('/verify-otp-code')
            }, 5000)
          } else {
            console.error('ERR::NoUserData - Authentication callback doesn\'t return a user data')
            renderToast('error', 'Login Error', 'Authentication failed. Please contact IT support.')
          }
        } else {
          console.error('ERR::NotSuccessful - Authentication callback doesn\'t return a success type')
          renderToast('error', 'Login Error', 'Authentication failed. Please contact IT support.')
        }
      }
    },
    togglePassword () {
      if (this.revealPassword) {
        this.revealPassword = false
      } else {
        this.revealPassword = true
      }
    }
  }
}

</script>
<style scoped>
.login-title {
  font-family: 'Baloo Extra Bold';
  font-size: 24px;
  font-weight: bolder;
  margin: 0 auto;
  margin-bottom: 20px;
  color: #1A2791;
}

.login-userpw-text {
  font-size: 16px;
  margin: 10px auto;
  padding-top: 10px;
}

.login-logo {
  margin: auto;
  margin-bottom: 0;
  height: 25px;
  width: auto;
}

.login-inputs {
  margin-bottom: 25px;
}

.login-container-card {
  text-align: center;
  margin-top: 0;
}

.login-system-title {
  text-align: right;
  font-family: "Proxima Nova";
  font-size: 0.77rem;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  bottom: 5px;
  z-index: 1;
}

.branding-container {
  background: #1A2791;
  display: flex;
  height: 100px;
  padding: 0;
}

.branding {
  margin: auto;
  margin-top: 30px;
}

.login-container {
  background: #FFF;
  font-family: "Proxima Nova";
  height: 100vh;
}

.ph-login-button {
  background: #FFF;
  color: #1A2791;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  width: 100%;
  transition: .2s;
  height: 60px !important;
}

/* .ph-login-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
  border: 2px solid #1A2791;
} */

.ph-login-button.ph-login-userpw {
  background: #1A2791;
  color: #fff;
  box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
  font-size: 14px;
  z-index: 1;
}

.ph-forget-password {
  margin-left: auto;
  color: #1A2791;
  margin-top: 5px;
  text-decoration: none;
  margin-top: 15px;
  font-size: 14px;
}

.ph-textbox-container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 0;
}

.google-logo {
  margin-right: auto;
}

.google-login-text {
  margin-right: auto;
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Proxima Nova Bold';
}

.login-card {
  margin: auto;
}

.ph-textbox {
  height: 50px;
}

.login-container-card .login-card {
  padding: 20px;
}

.footer {
  position: relative;
  top: 30px;
  font-size: 12px;
  text-align: center;
  margin: auto;
  padding: 10px 0;
}

.ph-login-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
}

.ph-divider-text {
  position: absolute;
  bottom: -25px;
  left: calc(50% - 21px);
  background: #FFF;
  padding: 0px 15px;
}

@media only screen and (min-width: 960px) {
  .branding-container {
    height: 100%;
  }

  .login-logo {
    height: 40px;
  }

  .login-system-title {
    font-size: 1.23rem;
  }

  .branding {
    margin-top: auto;
  }

  .footer {
    position: absolute;
    top: unset;
    bottom: 15px;
    width: 390px;
    text-align: center;
  }

  .login-container-card {
    margin-top: auto !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 892px) {
    .branding-container {
        width: 100%;
    }
}

@media only screen and (min-width: 500px) {
  .login-container-card {
    margin: auto;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
  }

  .login-card {
    width: 450px;
  }

  .login-container-card .login-card {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
