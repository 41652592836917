const environment = 'prod' // 'prod' or 'dev'

const configurations = {
  prod: {
    baseURL: process.env.VUE_APP_API_PROD_URL,
    token: process.env.VUE_APP_API_PROD_TOKEN,
    gcpBaseURL: 'U2FsdGVkX199/svxJuRWESmOA4IEQidUNrtAL7xqY6XDkQdGJayGJuS4L5CQWc7Xt9emTTJ/BCiehjJGW5/mkGbf1xcxtNO5oghb/AtEjtE='
  },
  dev: {
    baseURL: process.env.VUE_APP_API_DEV_URL,
    token: process.env.VUE_APP_API_DEV_TOKEN,
    gcpBaseURL: 'U2FsdGVkX186VFj/D5q9RKkgrVaQ+HDal5JHWcgKKhJCuRac/H0ZUISY7zpDrQIU7jawxDW4bZSM22r9HDY1sPiHI8Ay2Z9VO167XMzNNJg='
  }
}

export const env = {
  get: name => configurations[environment][name]
}
