import Vue from 'vue'
import VueCookies from 'vue-cookies'
import '@/plugins/axios'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

const baseDomain = 'perahub.com.ph'

Vue.config.productionTip = false

Vue.prototype.$uspMainAppURL = `https://usp20.${baseDomain}`

Vue.use(VueCookies, {
  expires: (60 * 20), // 20 minutes expiry
  domain: `.${baseDomain}`,
  secure: (process.env.NODE_ENV === 'production')
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
